import React, { useState, useEffect } from 'react';

const VisitorCounter = () => {
  const [visitorCount, setVisitorCount] = useState(1);

  useEffect(() => {
    const updateVisitorCount = () => {
      const newCount = Math.floor(Math.random() * 8) + 1; // Random number between 0 and 10
      setVisitorCount(newCount);
    };

    // Initial update
    updateVisitorCount();

    // Set interval to update every 25 seconds
    const intervalId = setInterval(updateVisitorCount, 25000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="visitor-count">
      <p className="count">{visitorCount}</p>
    </div>
  );
};

export default VisitorCounter;