export const workouts = [
  {
    id: 0,
    username: "_.crysty",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "https://www.instagram.com/_.crysty",
    tik: "https://www.tiktok.com/@_.crysty",
    type: "Full Body",
    description: "10 rounds for time :\n200 m run\n5 t2b\n10 burpee target*\n15 box jump\n20 wb*\n30s rest between rounds\n*-1 burpee + 1 wb each round",
    time: "37 minutes"
  },
  {
    id: 1,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 25 minutes:\n25 Air Squats\n25 Push Ups\n25 Kettlebell Swings\n25 Sit-Ups\n25 Box Jumps\n25 Lunges",
    time: "25 minutes"
  },
  {
    id: 2,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 22 minutes:\n25 Wall Balls (9/6kg)\n20 Deadlifts (100/70kg)\n15 Thrusters (50/35kg)\n10 Handstand Pushups\n5 Muscle Ups",
    time: "22 minutes"
  },
  {
    id: 3,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 20 minutes:\n3 Legless Ropeclimbs\n3 Bearcomplex with Barbell (60/40kg)\n3 Bar Muscle-Ups\n30 Airsquats\n300m run",
    time: "20 minutes"
  },
  {
    id: 4,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 30 minutes:\n17 Cal Row\n17 Cal Assualtbike\n17 Cal Ski-row\n17 Toes to Bar",
    time: "30 minutes"
  },
  {
    id: 5,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time:\n50 Benchpress\n50 Handrelease Push-Ups\n50 Deadlift\n50 Pullups\n50 Push Jerks\n50 Wallballs",
    time: "20 minutes"
  },
  {
    id: 6,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 20 minutes:\n5 Deadlift\n13 Push-Ups\n10 Boxjumps\n10 Burpees Over Bar",
    time: "20 minutes"
  },
  {
    id: 7,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "5 rounds of:\n15 Wall Balls\n15 Chest-to-bar pullups\n15 Single Arm dumbbell snatch\n10 Box jumps",
    time: "25 minutes"
  },
  {
    id: 8,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "E2MOM for 16 minutes:\n1 Bear Complex\n3 Handstand Push-Ups\n6 Toes to Bar \n10 Burpees",
    time: "16 minutes"
  },
  {
    id: 9,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 20 minutes:\n100 Benchpress\n100 Deadlifts\n100 Front Squat\nTime remaining in Assault Bike.",
    time: "20 minutes"
  },
  {
    id: 10,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 20 minutes:\n20 Calories Row\n20 Squats\n20 Toes-to-Bar\n20 Strict Press with Barbell",
    time: "20 minutes"
  },
  {
    id: 11,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 33 minutes:\n11 Burpees Over Wallball\n22 Wallballs\n33 Goblet Squats\n44 Double Unders\n55 Kettlebell Swings",
    time: "33 minutes"
  },
  {
    id: 12,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "3 Rounds for total reps in 17min:\n1min Burpees\n1min Power Snatch\n1min Thrusters\n1min Chest To Bar Pullups\n1min Rest",
    time: "17 minutes"
  },
  {
    id: 13,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 24 minutes:\n24 Calories Rowing\n12 Deadlifts\n12 Burpees Over Bar\n24 Toes-to-bar",
    time: "24 minutes"
  },
  {
    id: 14,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 24 minutes:\n50 Double Unders\n40 Pushups\n30 AbMat Sit-Ups\n20 Burpees\n10 Pullups",
    time: "24 minutes"
  },
  {
    id: 15,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time of:\n33 Deadlifts\n33 Chest To Bar Pullups\n33 Benchpress\n33 Toes-to-Bar\n33 Handstand Pushups\n33 Kettlebell Swings\n33 Front Squats\n33 Handrelease Pushups\n33 Double Dumbell Cleans",
    time: "40 minutes"
  },
  {
    id: 16,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time of:\n50 Walking Lunges\n40 Pullups\n30 Boxjumps\n50 Double Unders\n40 Kettlebell Swing\n30 Toes-to-Bar\n50 AbMat Situps\n40 Goblet Squats\n30 Wallballs",
    time: "45 minutes"
  },
  {
    id: 17,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time of:\n50 Calories Row\n40 Wallballs\n30 Toes-to-Bar\n20 Burpess Overbar\n10 Deadlifts",
    time: "28 minutes"
  },
  {
    id: 18,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time of:\n2000m Row\n60 Dumbell Thrusters\n60 Toes-to-Bar\n1500 Row\n40 Dumbell Thrusters\n40 Toes-to-Bar\n1000m Row\n20 Dumbell Thrusters\n20 Toes-to-Bar",
    time: "30-45 minutes"
  },
  {
    id: 19,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 30min of:\n15 Cal Ergo Bike\n15 Benchpress\n15 Burpees\n15 Box Step-Up with Dumbell",
    time: "30 minutes"
  },
  {
    id: 20,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time of:\n50 Wallballs\n40 Kettlebell Swings\n30 Burpees\n20 Russian Twists with KB\n10 Pushups\n20 Russian Twists with KB\n10 Pushups\n30 Burpees\n40 Kettlebell Swings\n50 Wallballs",
    time: "30-40 minutes"
  },
  {
    id: 21,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Cardio",
    description: "AMRAP for time of:\n900m running\n2min rest\n900m running\n2min rest\n900m running\n2min rest\n900m running\n2min Rest\n900m running",
    time: "20-30 minutes"
  },
  {
    id: 22,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "10 rounds for time of:\n10 Toes-to-Bar\n10 Pullups\n10 Power Cleans\n10 Push Press\n10 Calories Row",
    time: "30-40 minutes"
  },
  {
    id: 23,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time of:\n100 Pullups\n90 Pushups\n80 Air Squats\n70 Situps\n60 Wallballs\n50 Situps\n40 Airsquats\n30 Pushups\n20 Pullups",
    time: "30-40 minutes"
  },
  {
    id: 24,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "4 rounds for time:\n400m Run\n15 Overhead Squats (95/65 lbs)\n30 Russian Twists (20/14 lb plate)",
    time: "30-35 minutes"
  },
  {
    id: 25,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "10 Rounds for time of:\n20 Pushups\n30 Situps\n40 Air Squats\n200m Run",
    time: "20-30 minutes"
  },
  {
    id: 26,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time:\n50 Thrusters (75/55 lbs)\n50 Pull-ups\n50 Burpees\n50 Kettlebell Swings (53/35 lbs)\n50 Calorie Assault Bike",
    time: "35-40 minutes"
  },
  {
    id: 27,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 30 minutes:\n5 Muscle-ups\n10 Alternating Pistols\n15 Dumbbell Renegade Rows (2x50/35 lbs)",
    time: "30 minutes"
  },
  {
    id: 28,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "5 rounds for time:\n15 Deadlifts (225/155 lbs)\n20 Toes-to-Bar\n25 Wall Balls (20/14 lbs)\n400m Run",
    time: "40-45 minutes"
  },
  {
    id: 29,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "Every 4 minutes for 36 minutes:\n12 Power Cleans (115/75 lbs)\n12 Front Squats (115/75 lbs)\n12 Push Jerks (115/75 lbs)",
    time: "36 minutes"
  },
  {
    id: 30,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time:\n100 Double-Unders\n50 Burpee Box Jump-Overs (24/20 in)\n40 Hang Power Cleans (135/95 lbs)\n30 Strict Handstand Push-ups\n20 Bar Muscle-ups",
    time: "35-40 minutes"
  },
  {
    id: 31,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Cardio",
    description: "AMRAP in 40 minutes:\n5 Rope Climbs\n10 Bench Press (bodyweight)\n15 Box Jumps (30/24 in)\n20 Calorie Ski Erg",
    time: "40 minutes"
  },
  {
    id: 32,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "21-15-9 reps for time:\nSumo Deadlift High Pulls (95/65 lbs)\nBurpees Over the Bar\nRing Dips\n+\n400m Run after each round",
    time: "30-35 minutes"
  },
  {
    id: 33,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "Every 3 minutes for 33 minutes:\n10 Power Snatches (95/65 lbs)\n15 Pull-ups\n20 Alternating Lunges (10 each leg)",
    time: "33 minutes"
  },
  {
    id: 34,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time:\n50-40-30-20-10 reps of:\nWall Balls (20/14 lbs)\nKettlebell Swings (53/35 lbs)\n+\n10 Burpees after each round",
    time: "35-40 minutes"
  },
  {
    id: 35,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 45 minutes:\n7 Thrusters (135/95 lbs)\n7 Chest-to-Bar Pull-ups\n7 Bar-Facing Burpees",
    time: "45 minutes"
  },
  {
    id: 36,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "5 rounds for time:\n15 Dumbbell Clean and Jerks (50/35 lbs)\n20 GHD Sit-ups\n25 Calorie Assault Bike\n30 Double-Unders",
    time: "40-45 minutes"
  },
  {
    id: 37,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "Every 5 minutes for 35 minutes:\n10 Deadlifts (225/155 lbs)\n15 Toes-to-Bar\n20 Box Jump Overs (24/20 in)",
    time: "35 minutes"
  },
  {
    id: 38,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time:\n100 Calorie Row\n80 Wall Balls (20/14 lbs)\n60 Devil's Press (2x50/35 lbs dumbbells)\n40 Pull-ups\n20 Thrusters (135/95 lbs)",
    time: "35-40 minutes"
  },
  {
    id: 39,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 40 minutes:\n5 Strict Muscle-ups\n10 Single-Arm Dumbbell Snatches (50/35 lbs, alternating)\n15 Pistols (alternating)\n20 Calorie Ski Erg",
    time: "40 minutes"
  },
  {
    id: 40,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "21-15-9 reps for time:\nPower Cleans (135/95 lbs)\nRing Dips\nBox Jumps (30/24 in)\n+\n400m Run after each round",
    time: "30-35 minutes"
  },
  {
    id: 41,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "Every 4 minutes for 32 minutes:\n8 Hang Power Snatches (95/65 lbs)\n12 Burpee Box Jump-Overs (24/20 in)\n16 Russian Kettlebell Swings (53/35 lbs)",
    time: "32 minutes"
  },
  {
    id: 42,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "For time:\n50 Thrusters (75/55 lbs)\n40 Pull-ups\n30 Dumbbell Snatches (50/35 lbs)\n20 Handstand Push-ups\n10 Muscle-ups",
    time: "35-40 minutes"
  },
  {
    id: 43,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "AMRAP in 45 minutes:\n10 Power Cleans (135/95 lbs)\n20 Push-ups\n30 Air Squats\n40 Double-Unders",
    time: "45 minutes"
  },
  {
    id: 44,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "5 rounds for time:\n400m Run\n15 Dumbbell Thrusters (2x50/35 lbs)\n20 Pull-ups\n25 Sit-ups",
    time: "35-40 minutes"
  },
  {
    id: 45,
    username: "justmywod",
    flag: "🇸🇪",
    city: "Helsingborg",
    inst: "http//:www.instagram.com/justmywod",
    type: "Full Body",
    description: "Every 3 minutes for 30 minutes:\n5 Deadlifts (275/185 lbs)\n10 Bar Muscle-ups\n15 Wall Balls (20/14 lbs)",
    time: "30 minutes"
  },
  {
    id: 46,
    username: "_.crysty",
    flag: "🇪🇸",
    city: "Barcelona",
    inst: "https://www.instagram.com/_.crysty",
    tik: "https://www.tiktok.com/@_.crysty",
    type: "Cardio",
    description: "E2MO2M x 4 Rondas:\n25/20 cal row\n100 du\n400m Run\n25 Heavy wall ball",
    time: "32 minutes"
  },
  {
    id: 47,
    username: "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    inst: "https://www.instagram.com/crossfitdailywod",
    type: "Full Body",
    description: "AMRAP in 20 minutes:\n10 Burpees\n15 Kettlebell Swings\n20 Wall ball shots\n25 Double-Unders\n200m run",
    time: "20 minutes"
  },
  {
    id: 48,
    username: "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    inst: "https://www.instagram.com/crossfitdailywod",
    type: "Full Body",
    description: "EMOM in 20 minutes:\nMin 1-5: 10 Pushups\nMin 6-10: 15 Kettlebell swings\nMin 11-15: 20 Jumping Lounges\nMin 16-20: 10 Pullups",
    time: "20 minutes"
  },
  {
    "id": 49,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "AMRAP in 15 minutes:\n12 Diamond Pushups\n10 Strict Pull-ups\n8 Handstand Pushups\n15 Dumbbell Shoulder Press",
    "time": "15 minutes"
  },
  {
    "id": 50,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "5 Rounds for Time:\n20 Air Squats\n15 Box Jumps\n10 Walking Lunges (each leg)\n20 Calf Raises",
    "time": "25 minutes"
  },
  {
    "id": 51,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "Every 2 minutes for 16 minutes:\n8 Ring Dips\n12 Tricep Extensions\n15 Band Pull-aparts",
    "time": "16 minutes"
  },
  {
    "id": 52,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "Tabata for 16 minutes:\n20s work/10s rest\nSquat Jumps\nLunge Jumps\nWall Balls\nBox Step-ups",
    "time": "16 minutes"
  },
  {
    "id": 53,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "For Time:\n50 Push-ups\n40 Chin-ups\n30 Pike Push-ups\n20 Ring Rows\n10 Bar Muscle-ups",
    "time": "20 minutes"
  },
  {
    "id": 54,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "EMOM in 24 minutes:\nMin 1-6: 15 Goblet Squats\nMin 7-12: 12 Romanian Deadlifts\nMin 13-18: 20 Step-ups\nMin 19-24: 15 Jump Squats",
    "time": "24 minutes"
  },
  {
    "id": 55,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "21-15-9 reps for time:\nStrict Pull-ups\nDips\nPush Press\nBent Over Rows",
    "time": "18 minutes"
  },
  {
    "id": 56,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "10 Rounds for Time:\n10 Front Squats\n15 Bulgarian Split Squats\n20 Mountain Climbers",
    "time": "22 minutes"
  },
  {
    "id": 57,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "Every 3 minutes for 21 minutes:\n12 Bench Press\n10 Strict Pull-ups\n15 Lateral Raises",
    "time": "21 minutes"
  },
  {
    "id": 58,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "4 Rounds for Time:\n400m Run\n25 Walking Lunges\n20 Jump Squats\n15 Box Jumps",
    "time": "25 minutes"
  },
  {
    "id": 59,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "AMRAP in 20 minutes:\n8 Hand Release Push-ups\n10 Ring Rows\n12 Dumbbell Push Press\n6 Strict Pull-ups",
    "time": "20 minutes"
  },
  {
    "id": 60,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "For Time:\n100 Air Squats\n80 Alternating Lunges\n60 Jump Rope\n40 Box Step-ups\n20 Pistol Squats",
    "time": "23 minutes"
  },
  {
    "id": 61,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "5 Rounds for Time:\n15 Diamond Push-ups\n12 Face Pulls\n10 Pike Push-ups\n8 Chin-ups",
    "time": "19 minutes"
  },
  {
    "id": 62,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "EMOM in 18 minutes:\nMin 1-6: 20 Jump Squats\nMin 7-12: 15 Reverse Lunges\nMin 13-18: 12 Sumo Deadlifts",
    "time": "18 minutes"
  },
  {
    "id": 63,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "Tabata for 20 minutes:\n20s work/10s rest\nPush-ups\nBand Pull-aparts\nPike Push-ups\nInverted Rows",
    "time": "20 minutes"
  },
  {
    "id": 64,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "3 Rounds for Time:\n30 Air Squats\n20 Lunges\n10 Box Jumps\n400m Run",
    "time": "24 minutes"
  },
  {
    "id": 65,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "Every 4 minutes for 20 minutes:\n10 Strict Pull-ups\n15 Push-ups\n20 Band Pull-aparts",
    "time": "20 minutes"
  },
  {
    "id": 66,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "AMRAP in 22 minutes:\n15 Goblet Squats\n12 Box Step-ups\n10 Jump Lunges\n8 Pistol Squats",
    "time": "22 minutes"
  },
  {
    "id": 67,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "For Time:\n30 Ring Dips\n25 Pull-ups\n20 Handstand Push-ups\n15 Bar Muscle-ups",
    "time": "17 minutes"
  },
  {
    "id": 68,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "Every 2 minutes for 20 minutes:\n15 Front Squats\n10 Bulgarian Split Squats\n20 Calf Raises",
    "time": "20 minutes"
  },
  {
    "id": 69,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "EMOM in 15 minutes:\nMin 1-5: 12 Push Press\nMin 6-10: 10 Strict Pull-ups\nMin 11-15: 15 Lateral Raises",
    "time": "15 minutes"
  },
  {
    "id": 70,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "5 Rounds for Time:\n20 Walking Lunges\n15 Box Jumps\n10 Pistol Squats\n200m Run",
    "time": "21 minutes"
  },
  {
    "id": 71,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "AMRAP in 18 minutes:\n10 Ring Muscle-ups\n15 Dips\n20 Push-ups\n25 Band Pull-aparts",
    "time": "18 minutes"
  },
  {
    "id": 72,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "Tabata for 16 minutes:\n20s work/10s rest\nJump Squats\nBox Step-ups\nLunges\nCalf Raises",
    "time": "16 minutes"
  },
  {
    "id": 73,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "21-15-9 reps for time:\nBench Press\nStrict Pull-ups\nPike Push-ups\nFace Pulls",
    "time": "20 minutes"
  },
  {
    "id": 74,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "Every 3 minutes for 21 minutes:\n12 Front Squats\n15 Step-ups\n20 Jump Rope",
    "time": "21 minutes"
  },
  {
    "id": 75,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Upper Body",
    "description": "For Time:\n40 Push-ups\n30 Ring Rows\n20 Handstand Push-ups\n10 Bar Muscle-ups",
    "time": "19 minutes"
  },
  {
    "id": 76,
    "username": "CrossFitDailyWod",
    "flag": "🇩🇪",
    city: "Berlin",
    "inst": "https://www.instagram.com/crossfitdailywod",
    "type": "Lower Body",
    "description": "EMOM in 24 minutes:\nMin 1-8: 15 Air Squats\nMin 9-16: 12 Box Jumps\nMin 17-24: 10 Walking Lunges",
    "time": "24 minutes"
  }
]