import React from 'react';

const EventBanner = ({ 
  title,
  date,
  description,
  ctaText,
  ctaLink,
  backgroundImage,
}) => {
  const styles = `
    .event-banner {
      position: relative;
      width: 90%;
      overflow: hidden;
      background: linear-gradient(to right, rgba(211, 211, 211, 0.8), rgba(211, 211, 211, 0.4));
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .banner-background {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }

    .banner-background img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.1;
    }

    .banner-container {
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
      padding: 4rem 1rem;
    }

    .banner-content {
      text-align: center;
      color: white;
    }

    .event-date {
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #fde047;
      margin-bottom: 1rem;
      letter-spacing: 0.05em;
    }

    .event-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
      line-height: 1.2;
    }

    .event-description {
      font-size: 1.125rem;
      color: rgba(255, 255, 255, 0.9);
      max-width: 600px;
      margin: 0 auto 2rem;
      line-height: 1.6;
    }

    .event-promo {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.9);
      max-width: 85%;
      line-height: 1.6;
      margin: auto;
      margin-top: 5%;
    }

    .cta-button {
      display: inline-flex;
      padding: 0.875rem 2rem;
      background-color: white;
      color: #2563eb;
      text-decoration: none;
      border-radius: 9999px;
      font-weight: 600;
      transition: all 0.3s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .cta-button:hover {
      background-color: #f8fafc;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
      transform: translateY(-1px);
    }

    .decorative-circle {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      display: none;
    }

    .circle-1 {
      width: 160px;
      height: 160px;
      top: -80px;
      right: -80px;
    }

    .circle-2 {
      width: 240px;
      height: 240px;
      bottom: -120px;
      left: -120px;
    }

    /* Media Queries */
    @media (min-width: 640px) {
      .banner-container {
        padding: 5rem 2rem;
      }

      .event-banner {
        min-width: 90%
      } 

      .event-date {
        font-size: 1.125rem;
      }

      .event-title {
        font-size: 3rem;
      }

      .event-description {
        font-size: 1.25rem;
      }

       .event-promo {
        margin-top: 2%;
      }
    }

    @media (min-width: 1024px) {
      .banner-container {
        padding: 6rem 2rem;
      }

      .event-title {
        font-size: 3.5rem;
      }

      .decorative-circle {
        display: block;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="event-banner">
        {/* Background Image with Overlay 
        <div className="banner-background">
          <img src={backgroundImage} alt="Event background" />
        </div>
        */}

        {/* Content Container */}
        <div className="banner-container">
          {/* Event Info */}
          <div className="banner-content">
            <p className="event-date">{date}</p>
            <h2 className="event-title">{title}</h2>
            <p className="event-description">{description}</p>
            
            {/* CTA Button */}
            <a href={ctaLink} className="cta-button">
              {ctaText}
            </a>
            <p className="event-promo">To promote an event add a WOD & contact us <span> <a rel="noopener noreferrer" target="_blank" href="https://forms.gle/4Ytfn9uQnt8HGvdW9">here!</a></span></p>
          </div>

          {/* Decorative Elements */}
          <div className="decorative-circle circle-1"></div>
          <div className="decorative-circle circle-2"></div>
        </div>
      </div>
    </>
  );
};

export default EventBanner;